import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./LoginPage.css";
import FoveaAILogo from "../../assets/images/foveaai_logo_dark_text.svg";
import LoginForm from "../../components/LoginForm/LoginForm";
import { deleteCookie, validateToken } from "../../utils/SecurityUtils";

const LoginPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkTokenAndRedirect = async () => {
            const isValidToken = await validateToken();
            if (isValidToken) {
                navigate("/dashboard");
            } else {
                deleteCookie("AuthorizationToken");
            }
        };

        checkTokenAndRedirect();
    }, [navigate]);

    return (
        <div className="LoginPage">
            <div className="LogoContainer">
                <img className="FoveaAILogo" src={FoveaAILogo} alt="FoveaAI" />
            </div>
            <div className="Slogan">
                Identify top candidates in minutes.
            </div>
            <div className="LoginArea">
                <h1>Login</h1>
            </div>
            {/* Render the LoginForm component */}
            <LoginForm />
        </div>
    );
};

export default LoginPage;
