import "./JobDetailForm.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, FormGroup, Label, Input, Container } from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import MatchBiasSlider from "../MatchBiasSlider/MatchBiasSlider";

const JobDetailForm = ({
    jobId = null,
    job_object = null,
    closeModal = null,
    triggerReload = null,
}) => {
    const [job, setJob] = useState({
        title: "",
        client: "",
        contact: "",
        industry: "",
        key_requirements: "",
        description: "",
        requirements: "",
        desirables: "",
        skills: "",
        min_years: 0,
        max_years: 0,
        match_bias: 50,
        locality: "",
        administrative_area: "",
        country: "",
        postal_code: "",
    });
    const [cookies] = useCookies(["AuthorizationToken"]);
    const [isEditing, setIsEditing] = useState(false); // Adding new or editing exsiting job
    const [matchSliderValue, setMatchSliderValue] = useState(50);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchJobDetails = async () => {
            try {
                const response = await axios.get(
                    `https://api.foveaai.com/evaluate/web/jobs/${jobId}`,
                    {
                        headers: { Authorization: cookies.AuthorizationToken },
                    }
                );
                const jobData =
                    typeof response.data === "string"
                        ? JSON.parse(response.data)
                        : response.data;
                setJob(jobData);

                // Set the match slider value to the job's match_bias
                // if the job object is fetched successfully and has a match_bias
                // that is not null or "".
                if (
                    jobData.match_bias !== null &&
                    jobData.match_bias !== undefined
                ) {
                    setMatchSliderValue(jobData.match_bias);
                }
            } catch (error) {
                console.error("Error fetching job details:", error);
            }
        };

        // Fetch the job details if the job object is not provided
        if (jobId && job_object == null) {
            fetchJobDetails();
            setIsEditing(true);
        }

        // Otherwise, use the job object
        else if (job_object != null) {
            setJob(job_object);
            setIsEditing(true);
        }

        // We are creating a new job object
        else {
            setIsEditing(false);
        }
    }, [jobId, job_object, cookies.AuthorizationToken]);

    const handleMatchSliderChange = (value) => {
        // Set the match slider value and update the job state
        setMatchSliderValue(value);
        setJob({ ...job, match_bias: value });
    };

    // Log the job object whenever it changes for debugging
    useEffect(() => {
        console.log("Job object:", job);
    }, [job]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Check if the field being changed is 'max_years' or 'min_years'
        if (name === "max_years" || name === "min_years") {
            const floatValue = parseFloat(value);
            // Upate the state with the converted float value or 0 if not a number
            setJob({ ...job, [name]: isNaN(floatValue) ? 0 : floatValue });
        } else {
            // Update the state with the new value
            setJob({ ...job, [name]: value });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Editing an existing job, so use the PUT method
        if (isEditing) {
            try {
                console.log("JobDetailForm put job:", job);
                const response = await axios.put(
                    `https://api.foveaai.com/evaluate/web/jobs/${jobId}`,
                    job,
                    { headers: { Authorization: cookies.AuthorizationToken } }
                );

                // Get the updated job id from the response
                const job_response =
                    typeof response.data === "string"
                        ? JSON.parse(response.data)
                        : response.data;
                setJob(job_response);

                // Redirect to the job page using the updated job's ID with the React Router
                if (triggerReload) triggerReload();
                if (closeModal) closeModal();
            } catch (error) {
                console.error("Error updating job details:", error);
            }

            // Adding a new job, so use the POST method
        } else {
            try {
                console.log("JobDetailForm post job:", job);
                const response = await axios.post(
                    "https://api.foveaai.com/evaluate/web/jobs",
                    job,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: cookies.AuthorizationToken,
                        },
                    }
                );

                // Get the new job id from the response
                const job_response =
                    typeof response.data === "string"
                        ? JSON.parse(response.data)
                        : response.data;
                setJob(job_response);

                // Redirect to the job page using the new job's ID with the React Router
                if (closeModal) closeModal();
                navigate(`/job/${job_response.job_id}`);
            } catch (error) {
                console.error("Error updating job details:", error);
            }
        }
    };

    return (
        <Container>
            <div className="job-detail-form-custom">
                <Form onSubmit={handleSubmit}>
                    <Tabs forceRenderTabPanel={true}>
                        <TabList>
                            <Tab>General</Tab>
                            <Tab>Location</Tab>
                            <Tab>Description</Tab>
                            <Tab>Fit Adjustments</Tab>
                        </TabList>
                        <TabPanel>
                            <div className="job-detail-form-section-heading">
                                <h2>Job Information</h2>
                            </div>
                            <div className="job-detail-form-section-description">
                                Changes will be applied to added candidate evaluations.
                            </div>
                            <div className="job-detail-form-groups">
                                <FormGroup>
                                    <Label for="title">Title</Label>
                                    <Input
                                        type="text"
                                        name="title"
                                        id="title"
                                        value={job.title || ""}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="client">Client</Label>
                                    <Input
                                        type="text"
                                        name="client"
                                        id="client"
                                        value={job.client || ""}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="contact">Contact</Label>
                                    <Input
                                        type="text"
                                        name="contact"
                                        id="contact"
                                        value={job.contact || ""}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="industry">Industry</Label>
                                    <Input
                                        type="text"
                                        name="industry"
                                        id="industry"
                                        value={job.industry || ""}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="job-detail-form-section-heading">
                                <h2>Job Location</h2>
                            </div>
                            <div className="job-detail-form-section-description">
                                Changes will be applied to added candidate evaluations.
                            </div>
                            <div className="job-detail-form-groups">
                                <FormGroup>
                                    <Label for="locality">City</Label>
                                    <Input
                                        type="text"
                                        name="locality"
                                        id="locality"
                                        value={job.locality || ""}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="administrative_area">
                                        State
                                    </Label>
                                    <Input
                                        type="text"
                                        name="administrative_area"
                                        id="administrative_area"
                                        value={job.administrative_area || ""}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="country">Country</Label>
                                    <Input
                                        type="text"
                                        name="country"
                                        id="country"
                                        value={job.country || ""}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="postal_code">Postal Code</Label>
                                    <Input
                                        type="text"
                                        name="postal_code"
                                        id="postal_code"
                                        value={job.postal_code || ""}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="job-detail-form-section-heading">
                                <h2>Job Description/Requirements</h2>
                            </div>
                            <div className="job-detail-form-section-description">
                                Changes will be applied to added candidate evaluations.
                            </div>
                            <div className="job-detail-form-groups">
                                <FormGroup>
                                    <Label for="key_requirements">
                                        Key Requirements
                                    </Label>
                                    <Input
                                        type="textarea"
                                        rows="5"
                                        name="key_requirements"
                                        id="key_requirements"
                                        value={job.key_requirements || ""}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="description">Description</Label>
                                    <Input
                                        type="textarea"
                                        rows="5"
                                        name="description"
                                        id="description"
                                        value={job.description || ""}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="requirements">
                                        Requirements
                                    </Label>
                                    <Input
                                        type="textarea"
                                        rows="5"
                                        name="requirements"
                                        id="requirements"
                                        value={job.requirements || ""}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="desirables">Desirables</Label>
                                    <Input
                                        type="textarea"
                                        rows="5"
                                        name="desirables"
                                        id="desirables"
                                        value={job.desirables || ""}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="skills">Skills</Label>
                                    <Input
                                        type="text"
                                        name="skills"
                                        id="skills"
                                        value={job.skills || ""}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="job-detail-form-section-heading">
                                <h2>Fit Adjustments</h2>
                            </div>
                            <div className="job-detail-form-section-description">
                                Changes are <span style={{ textDecoration: 'underline' }}>instantly applied to existing and added</span> candidate evaluations.
                            </div>
                            <div className="job-detail-form-groups">
                                <FormGroup>
                                    <Label for="min_years">
                                        Preferred Min Years of Job Related
                                        Experience
                                    </Label>
                                    <Input
                                        type="number"
                                        name="min_years"
                                        id="min_years"
                                        value={job.min_years}
                                        onChange={handleInputChange}
                                        min={1}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="max_years">
                                        Preferred Max Years of Job Related
                                        Experience
                                    </Label>
                                    <Input
                                        type="number"
                                        name="max_years"
                                        id="max_years"
                                        value={job.max_years}
                                        onChange={handleInputChange}
                                        min={1}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="match_bias">
                                        Job Related Experience Match Flexibility
                                    </Label>
                                    <MatchBiasSlider
                                        matchSliderValue={matchSliderValue}
                                        onMatchSliderChange={
                                            handleMatchSliderChange
                                        }
                                        disabled={false}
                                    />
                                </FormGroup>
                            </div>
                        </TabPanel>
                    </Tabs>
                    {closeModal && (
                        <Button
                            color="secondary"
                            onClick={closeModal}
                            style={{ marginTop: "10px", marginRight: "10px" }}
                        >
                            Cancel
                        </Button>
                    )}
                    <Button
                        color="primary"
                        type="submit"
                        style={{ marginTop: "10px", marginLeft: "10px" }}
                    >
                        {isEditing ? "Save All Changes" : "Add Job"}
                    </Button>
                </Form>
            </div>
        </Container>
    );
};

export default JobDetailForm;
