import React from 'react';
import { Spinner } from 'react-bootstrap';
import './LoadingOverlay.css';

const LoadingOverlay = () => {
    return (
        <div className="loadingOverlay">
            <Spinner animation="border" variant="light" />
        </div>
    );
};

export default LoadingOverlay;