import "./DashboardPage.css";
import FoveaAILogo from "../../assets/images/foveaai_logo_dark_text.svg";

import React from "react";
import JobDetailForm from "../../components/JobDetailForm/JobDetailForm";
import JobTable from "../../components/JobTable/JobTable";
import { Modal } from "react-bootstrap";
import { Container, Button } from "reactstrap";

function DashboardPage() {
    const [showEditModal, setShowEditModal] = React.useState(false);

    const handleEditModalClose = () => {
        setShowEditModal(false);
    };

    return (
        <Container>
            <Modal
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                size="lg"
                aria-labelledby="contained-model-title-vcenter"
                centered
                dialogClassName="job-detail-form-model-custom"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-model-title-vcenter">
                        Add New Job Description
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="job-detail-form-modal-body-custom">
                    <div className="dashboard-new-job-modal-description">
                        <p>
                            Fill out the fields below to add a new job to
                            FoveaAI. For the best evaluation results, pay
                            particular attention to the most important skills,
                            experience, and education you would like to see in
                            the ideal candidate.
                        </p>
                        <p>
                            Be thoughtful in your job description. While all
                            fields are considered, extra attention is paid to
                            the job title and the key requirements. Key
                            requirements are the most important skills and
                            experience that a candidate must have to be
                            successful. Essentially, if a candidate meets the
                            key requirements, you would like to know more about
                            them.
                        </p>
                        <p>
                            Include both the minimum and maximum number of years
                            of experience you would like to see in a candidate.
                            Maximum years of exerpience is a non-standard
                            metric. It can be considered that candidates above
                            this value are overqualified for both the position
                            and the compensation offered.
                        </p>
                    </div>
                    <JobDetailForm
                        jobId={null}
                        closeModal={handleEditModalClose}
                    />
                </Modal.Body>
            </Modal>

            <div className="dashboard-info-container">
                <img
                    className="dashboard-info-logo"
                    src={FoveaAILogo}
                    alt="FoveaAI Logo"
                />
                <div className="dashboard-tagline">
                    Identify top candidates in minutes.
                </div>
            </div>
            <div className="dashboard-container">
                <div className="dashboard-heading">
                    <h1>Open Jobs</h1>
                </div>
                <div className="dashboard-instructions-container">
                    <div className="dashboard-instructions">
                        Use the "Add Job" button to create a new job
                        description. Click on a job in the list below to upload
                        resumes and review candidate evaluations.
                    </div>
                    <Button
                        color="success"
                        className="dashboard-button"
                        onClick={() => setShowEditModal(true)}
                    >
                        Add Job
                    </Button>
                </div>
                <JobTable />
            </div>
        </Container>
    );
}

export default DashboardPage;
