import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingOverlay from '../components/LoadingOverlay/LoadingOverlay';

const EULA_VERSION_ID = 2;

export const setCookie = (name, value, daysToLive) => {
    // Encode value in order to escape semicolons, commas, and whitespace
    let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; path=/`;    

    if (daysToLive) {
        const date = new Date();
        date.setTime(date.getTime() + (daysToLive * 24 * 60 * 60 * 1000));
        cookie += `; expires=${date.toUTCString()}`;
    }

    document.cookie = cookie;
};

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return decodeURIComponent(parts.pop().split(';').shift());
};

export const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export const validateToken = async () => {
    const token = getCookie('AuthorizationToken');
    if (!token) return false;

    // Send a post to https://api.foveaai.com/evaluate/token/validate with
    // the token in the "Authorization" header and test for a status 200
    // response to confirm the token is valid
    try {
        const response = await axios.post('https://api.foveaai.com/evaluate/token/validate', {}, {
            headers: {
                'Authorization': token
            }
        });
        return response.status === 200;
    } catch (error) {
        console.error('Token validation error: ', error);
        return false;
    }
}

export const checkEulaAcceptance = async () => {
    const token = getCookie('AuthorizationToken');
    if (!token) return false;

    let eulaResponse;

    try {
        const eulaResponseString = await axios.get(`https://api.foveaai.com/evaluate/web/eula/acceptances/${EULA_VERSION_ID}`, {
            headers: { 
                'Authorization': token 
            }
        });

        // Parse the response JSON into eulaResponse
        eulaResponse = JSON.parse(eulaResponseString.data);

    } catch (error) {
        console.error('EULA acceptance check error:', error);
        return false;
    }

    // We received a valid accepted EULA response
    if (eulaResponse && eulaResponse.accepted === true) {
        return true;
    }

    // We did not receive a valid accepted EULA response
    else {
        return false;
    }
}

export const logout = () => {
    setCookie('AuthorizationToken', '', 0);
    window.location.href = '/';
}

export const ProtectedRoute = ({ children, requireEula = true }) => {
    const [isValidating, setIsValidating] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        // The checkAccess function will validate the token and check EULA acceptance
        // if the page rquires it.
        const checkAccess = async () => {
            const tokenValid = await validateToken();
            if (!tokenValid) {
                setIsValidating(false);
                navigate('/login');
                return;
            }

            if (requireEula) {
                // Assume a specific EULA version ID, or fetch from a suitable source
                const checkEula = await checkEulaAcceptance();

                // Did not get a valid EULA acceptance response
                if (!checkEula) {
                    setIsValidating(false);
                    navigate('/eula');
                    return;
                }
            }
            setIsValidating(false);
        };

        // Run the checkAccess function
        checkAccess();
    }, [navigate, requireEula]);

    // While validating, show a loading overlay
    if (isValidating) {
        return <LoadingOverlay />;
    }

    // Made it through validation and EULA check so render the protected route
    return children;
}