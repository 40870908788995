import "./AnalysisDetailForm.css";

import ExperienceDetailForm from "../ExperienceDetailForm/ExperienceDetailForm";
import EducationDetailForm from "../EducationDetailForm/EducationDetailForm";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button, Form, FormGroup, Label, Input, Container } from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useCookies } from "react-cookie";
// import { el } from "date-fns/locale";

const AnalysisDetailForm = ({
    analysisId = null,
    analysis_object = null,
    closeModal = null,
    refreshData = null,
}) => {
    const [analysis, setAnalysis] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [cookies] = useCookies(["AuthorizationToken"]);
    const experienceRef = useRef({});
    const educationRef = useRef({});
    const [activeTab, setActiveTab] = useState(0);
    const [showNewExperienceForm, setShowNewExperienceForm] = useState(false);
    const [showNewEducationForm, setShowNewEducationForm] = useState(false);
    const [experienceCounter, setExperienceCounter] = useState(0);
    const [educationCounter, setEducationCounter] = useState(0);

    useEffect(() => {
        const fetchAnalysisDetails = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(
                    `https://api.foveaai.com/evaluate/web/analyses/${analysisId}`,
                    {
                        headers: { Authorization: cookies.AuthorizationToken },
                    }
                );
                const analysisData =
                    typeof response.data === "string"
                        ? JSON.parse(response.data)
                        : response.data;
                setAnalysis(analysisData);
            } catch (error) {
                console.error("Error fetching analysis details:", error);
            } finally {
                setIsLoading(false);
            }
        };

        if (analysis_object == null) {
            fetchAnalysisDetails();
        } else {
            setAnalysis(analysis_object);
        }
    }, [analysisId, analysis_object, cookies.AuthorizationToken]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (analysis && analysis.id) {
            // Perform the candidate fields update
            const candidate_data = {
                first_name: analysis.first_name,
                last_name: analysis.last_name,
                locality: analysis.locality,
                administrative_area: analysis.administrative_area,
                postal_code: analysis.postal_code,
                email: analysis.email,
                phone: analysis.phone,
                skill_set: analysis.skill_set,
                primary_job_title: analysis.primary_job_title,
            };

            // Loop through the experiences and update or add them
            const experiencePromises = Object.entries(experienceRef.current).map(
                ([key, expRef]) => {
                    // Check if expRef is a valid object and has the necessary methods
                    if (expRef && typeof expRef === 'object' && 'isNewEntry' in expRef) {
                        if (expRef.isNewEntry === true) {
                            console.log("Processing new experience:", key);
                            return expRef.handleAddExperience().then(response => {
                                return response;
                            });
                        } else {
                            console.log("Processing existing experience:", key);
                            return expRef.handleUpdateExperience().then(response => {
                                return response;
                            });
                        }
                    }
                    console.log("Skipping invalid experience reference:", key);
                    return Promise.resolve(); // Skip if ref is not properly set
                }
            );

            const educationPromises = Object.entries(educationRef.current).map(
                ([key, eduRef]) => {
                    // Check if eduRef is a valid object and has the necessary methods
                    if (eduRef && typeof eduRef === 'object' && 'isNewEntry' in eduRef) {
                        if (eduRef.isNewEntry === true) {
                            console.log("Processing new education:", key);
                            return eduRef.handleAddEducation().then(response => {
                                return response;
                            });
                        } else {
                            console.log("Processing existing education:", key);
                            return eduRef.handleUpdateEducation().then(response => {
                                return response;
                            });
                        }
                    }
                    console.log("Skipping invalid education reference:", key);
                    return Promise.resolve(); // Skip if ref is not properly set
                }
            );

            try {
                try {
                    await axios.put(
                        `https://api.foveaai.com/evaluate/web/candidates/${analysis.analysis.candidate_id}`,
                        candidate_data,
                        {
                            headers: {
                                Authorization: cookies.AuthorizationToken,
                            },
                        }
                    );
                } catch (error) {
                    console.error("Error updating candidate fields:", error);
                }

                // Perform the analysis fields update
                const analysis_data = {
                    job_fit_rating_normalized:
                        analysis.analysis.job_fit_rating_normalized,
                    candidate_summary: analysis.analysis.candidate_summary,
                    job_fit_narrative: analysis.analysis.job_fit_narrative,
                    interview_questions: analysis.analysis.interview_questions,
                    notes: analysis.analysis.notes,
                    url: analysis.analysis.url,
                    url_anchor_text: analysis.analysis.url_anchor_text,
                };

                try {
                    await axios.put(
                        `https://api.foveaai.com/evaluate/web/analyses/${analysis.analysis.id}`,
                        analysis_data,
                        {
                            headers: {
                                Authorization: cookies.AuthorizationToken,
                            },
                        }
                    );
                } catch (error) {
                    console.error("Error updating analysis fields:", error);
                }

                try {
                    // Perform all of the experience updates
                    await Promise.all(experiencePromises);
                    
                    // Close the new experience form
                    setShowNewExperienceForm(false);

                    // Reset the counter
                    setExperienceCounter(0);
                } catch (error) {
                    console.error("Error updating or adding experience fields:", error);
                }

                try {
                    // Perform all of the education updates
                    await Promise.all(educationPromises);

                    // Close the new education form
                    setShowNewEducationForm(false);

                    // Reset the counter
                    setEducationCounter(0);
                } catch (error) {
                    console.error("Error updating or adding education fields:", error);
                }
            } finally {
                // Return even if there is an error
                if (refreshData) {
                    refreshData();
                }
                setIsLoading(false);
                if (closeModal) {
                    closeModal();
                }
            }
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setAnalysis((prevAnalysis) => {
            const keys = name.split(".");
            let lastRef = prevAnalysis;
            keys.slice(0, -1).forEach((key) => {
                if (!lastRef[key]) lastRef[key] = {}; // Initialize if does not exist
                lastRef = lastRef[key];
            });
            lastRef[keys[keys.length - 1]] = value;
            return { ...prevAnalysis };
        });
    };

    // Function to handle experience deletion
    const handleExperienceDelete = (deletedExperienceId) => {
        setAnalysis(prevAnalysis => ({
            ...prevAnalysis,
            experiences: prevAnalysis.experiences.filter(exp => exp.id !== deletedExperienceId)
        }));
        // Ensure the experience tab (index 3) remains selected after deletion
        setActiveTab(3);

        // Call refreshData to update parent component
        if (refreshData) {
            refreshData();
        }
    };

    // Function to handle education deletion
    const handleEducationDelete = (deletedEducationId) => {
        setAnalysis(prevAnalysis => ({
            ...prevAnalysis,
            educations: prevAnalysis.educations.filter(edu => edu.id !== deletedEducationId)
        }));
        // Ensure the education tab (index 4) remains selected after deletion
        setActiveTab(4);

        // Call refreshData to update parent component
        if (refreshData) {
            refreshData();
        }
    };

    // Function to handle tab selection
    const handleTabSelect = (index) => {
        setActiveTab(index);
    };

    const handleAddExperience = () => {
        setShowNewExperienceForm(true);
        setExperienceCounter(prev => prev + 1);
    };

    const handleAddEducation = () => {
        setShowNewEducationForm(true);
        setEducationCounter(prev => prev + 1);
    };

    const handleNewExperienceAdd = (newExperience) => {
        // Add the new experience to the analysis state
        console.log("Adding new experience to form list:", newExperience);

        setAnalysis(prevAnalysis => ({
            ...prevAnalysis,
            experiences: [...prevAnalysis.experiences, newExperience]
        }));

        setShowNewExperienceForm(false);
    
        // Ensure the experience tab (index 3) remains selected after deletion
        setActiveTab(3);

        // Call refreshData to update parent component
        if (refreshData) {
            refreshData();
        }
    };

    const handleNewEducationAdd = (newEducation) => {
        // Add the new education to the analysis state
        console.log("Adding new education to form list:", newEducation);

        setAnalysis(prevAnalysis => ({
            ...prevAnalysis,
            educations: [...prevAnalysis.educations, newEducation]
        }));

        setShowNewEducationForm(false);

        // Ensure the education tab (index 4) remains selected after deletion
        setActiveTab(4);

        // Call refreshData to update parent component
        if (refreshData) {
            refreshData();
        }
    };

    return (
        <Container>
            {isLoading && <LoadingOverlay />}
            <Form onSubmit={handleSubmit}>
                <Tabs forceRenderTabPanel={true} selectedIndex={activeTab} onSelect={handleTabSelect}>
                    <TabList>
                        <Tab>Candidate</Tab>
                        <Tab>Evaluation</Tab>
                        <Tab>Recruiter Notes</Tab>
                        <Tab>Experience</Tab>
                        <Tab>Education</Tab>
                    </TabList>
                    <TabPanel>
                        <div className="analysis-detail-form-section-heading">
                            <h2>Candidate Information</h2>
                        </div>
                        <div className="analysis-detail-form-groups">
                            <FormGroup>
                                <Label for="first_name">First Name</Label>
                                <Input
                                    type="text"
                                    name="first_name"
                                    id="first_name"
                                    value={analysis?.first_name ?? ""}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="last_name">Last Name</Label>
                                <Input
                                    type="text"
                                    name="last_name"
                                    id="last_name"
                                    value={analysis?.last_name ?? ""}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="locality">City</Label>
                                <Input
                                    type="text"
                                    name="locality"
                                    id="locality"
                                    value={analysis?.locality ?? ""}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="administrative_area">State</Label>
                                <Input
                                    type="text"
                                    name="administrative_area"
                                    id="administrative_area"
                                    value={analysis?.administrative_area ?? ""}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="postal_code">Zip Code</Label>
                                <Input
                                    type="text"
                                    name="postal_code"
                                    id="postal_code"
                                    value={analysis?.postal_code ?? ""}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input
                                    type="text"
                                    name="email"
                                    id="email"
                                    value={analysis?.email ?? ""}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="phone">Phone</Label>
                                <Input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    value={analysis?.phone ?? ""}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="analysis-detail-form-section-heading">
                            <h2>Evaluation</h2>
                        </div>
                        <div className="analysis-detail-form-groups">
                            <FormGroup>
                                <Label for="primary_job_title">
                                    Primary Job Title
                                </Label>
                                <Input
                                    type="text"
                                    name="primary_job_title"
                                    id="primary_job_title"
                                    value={analysis?.primary_job_title ?? ""}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="skill_set">Skill Set</Label>
                                <Input
                                    type="textarea"
                                    rows="2"
                                    name="skill_set"
                                    id="skill_set"
                                    value={analysis?.skill_set ?? ""}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="candidate_summary">
                                    Candidate Summary
                                </Label>
                                <Input
                                    type="textarea"
                                    rows="5"
                                    name="analysis.candidate_summary"
                                    id="candidate_summary"
                                    value={
                                        analysis?.analysis?.candidate_summary ??
                                        ""
                                    }
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="job_fit_narrative">
                                    Strengths & Weaknesses
                                </Label>
                                <Input
                                    type="textarea"
                                    rows="5"
                                    name="analysis.job_fit_narrative"
                                    id="job_fit_narrative"
                                    value={
                                        analysis?.analysis?.job_fit_narrative ??
                                        ""
                                    }
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="interview_questions">
                                    Interview Questions
                                </Label>
                                <Input
                                    type="textarea"
                                    rows="5"
                                    name="analysis.interview_questions"
                                    id="interview_questions"
                                    value={
                                        analysis?.analysis
                                            ?.interview_questions ?? ""
                                    }
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="analysis-detail-form-section-heading">
                            <h2>Recruiter Notes</h2>
                        </div>
                        <div className="analysis-detail-form-groups">
                            <FormGroup>
                                <Label for="notes">Notes</Label>
                                <Input
                                    type="textarea"
                                    name="analysis.notes"
                                    id="notes"
                                    value={analysis?.analysis?.notes ?? ""}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="url">
                                    Additional Info Link URL
                                </Label>
                                <Input
                                    type="text"
                                    name="analysis.url"
                                    id="url"
                                    value={analysis?.analysis?.url ?? ""}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="url_anchor_text">
                                    Additional Info Link Text
                                </Label>
                                <Input
                                    type="text"
                                    name="analysis.url_anchor_text"
                                    id="url_anchor_text"
                                    value={
                                        analysis?.analysis?.url_anchor_text ??
                                        ""
                                    }
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="analysis-detail-form-section-heading">
                            <h2>Candidate Experience</h2>
                        </div>
                        {analysis?.experiences?.map((experience, index) => (
                            <div className="experience-detail-form-groups" key={experience.id}>
                                <ExperienceDetailForm
                                    ref={(el) => (experienceRef.current[experience.id] = el)}
                                    experience_object={experience}
                                    experience_id={experience.id}
                                    candidate_id={experience.candidate_id}
                                    onDelete={handleExperienceDelete}
                                />
                            </div>
                        ))}
                        {showNewExperienceForm && (
                            <div className="experience-detail-form-groups">
                                <ExperienceDetailForm
                                    ref={(el) => (experienceRef.current[`new_${experienceCounter}`] = el)}
                                    candidate_id={analysis.analysis.candidate_id}
                                    isNewEntry={true}
                                    onCancelAdd={() => setShowNewExperienceForm(false)}
                                    onAdd={handleNewExperienceAdd}
                                />
                            </div>
                        )}
                    </TabPanel>
                    <TabPanel>
                        <div className="analysis-detail-form-section-heading">
                            <h2>Candidate Education</h2>
                        </div>
                        {analysis?.educations?.map((education, index) => (
                            <div className="education-detail-form-groups" key={education.id}>
                                <EducationDetailForm
                                    ref={(el) => (educationRef.current[education.id] = el)}
                                    education_object={education}
                                    education_id={education.id}
                                    candidate_id={education.candidate_id}
                                    onDelete={handleEducationDelete}
                                />
                            </div>
                        ))}
                        {showNewEducationForm && (
                            <div className="education-detail-form-groups">
                                <EducationDetailForm
                                    ref={(el) => (educationRef.current[`new_${educationCounter}`] = el)}
                                    candidate_id={analysis.analysis.candidate_id}
                                    isNewEntry={true}
                                    onCancelAdd={() => setShowNewEducationForm(false)}
                                    onAdd={handleNewEducationAdd}
                                />
                            </div>
                        )}
                    </TabPanel>
                </Tabs>
                <div className="form-buttons">
                    {closeModal && (
                        <Button
                            color="secondary"
                            onClick={closeModal}
                            className="mr-2"
                        >
                            Cancel
                        </Button>
                    )}
                    {activeTab === 3 && (
                        <Button
                            color="success"
                            onClick={handleAddExperience}
                            className="mr-2"
                        >
                            Add Experience
                        </Button>
                    )}
                    {activeTab === 4 && (
                        <Button
                            color="success"
                            onClick={handleAddEducation}
                            className="mr-2"
                        >
                            Add Education
                        </Button>
                    )}
                    <Button
                        color="primary"
                        type="submit"
                    >
                        Save All Changes
                    </Button>
                </div>
            </Form>
        </Container>
    );
};

export default AnalysisDetailForm;
