import "./Footer.css"

const Footer = () => {
    return (
        <div className="footer">
                    <a
                        className="App-link white-text"
                        href="https://foveaai.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        FoveaAI
                    </a> - Copyright 2024, All Rights Reserved
        </div>
    );
};

export default Footer;