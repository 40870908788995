import "./EulaPage.css";
import EulaForm from "../../components/EulaForm/EulaForm";

function EulaPage() {
    return (
        <div className="eula-container">
            <EulaForm />
        </div>
    );
}

export default EulaPage;
