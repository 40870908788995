import "./AnalysisPage.css";
import { useParams } from "react-router-dom";
import AnalysisDetail from "../../components/AnalysisDetail/AnalysisDetail";

function AnalysisPage() {
    const { analysisId } = useParams();

    return (
        <div className="analysis-container">
            <div className="analysis-items">
                <div className="analysis-page-heading">
                    <h1>Candidate Evaluation</h1>
                </div>
                <div className="analysis-detail-container">
                    <AnalysisDetail analysisId={analysisId} />
                </div>
            </div>
        </div>
    );
}

export default AnalysisPage;
