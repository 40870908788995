import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';  // Import default styles, essential for proper styling of the slider

const marks = {
    0: <div style={{ whiteSpace: 'pre-wrap' }}>Generally<br/>Related</div>,
    50: <div style={{ whiteSpace: 'pre-wrap' }}>Closely<br/>Related</div>,
    100: <div style={{ whiteSpace: 'pre-wrap' }}>Nearly<br/>Identical</div>
};

const MatchBiasSlider = ({ matchSliderValue, onMatchSliderChange, disabled }) => {

    return (
        <div style={{ margin: 50 }}>
            <Slider
                marks={marks}
                step={null}
                value={matchSliderValue}
                defaultValue={50}
                min={0}
                max={100}
                onChange={onMatchSliderChange}
                disabled={disabled}
            />
        </div>
    );
};

export default MatchBiasSlider;