import "./EducationDetailForm.css";

import axios from "axios";
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Form, FormGroup, Label, InputGroup, Input, Container, Button } from "reactstrap";
import { useCookies } from "react-cookie";

const EducationDetailForm = forwardRef(({
    education_object = null,
    education_id = null,
    candidate_id = null,
    isNewEntry = false,
    onDelete = null,
    onCancelAdd = null,
    onAdd = null,
}, ref) => {

    const [original_to_date, setOriginalToDate] = useState(null);
    const [cookies] = useCookies(["user"]);
    const [education, setEducation] = useState({
        id: null,
        candidate_id: null,
        degree: null,
        major: null,
        school: null,
        from_date: null,
        to_date: null,
        currently_pursuing: null,
    });

    const [showConfirmingDelete, setShowConfirmingDelete] = useState(false);
    const [showConfirmingCancel, setShowConfirmingCancel] = useState(false);

    useEffect(() => {
        if (education_object) {
            setEducation(education_object);
            setOriginalToDate(education_object.to_date);
        }
    }, [education_object]);

    // Expose the update, add, and new entry status to the parent component
    useImperativeHandle(ref, () => ({
        handleUpdateEducation,
        handleAddEducation,
        isNewEntry,
    }));

    const handleChange = (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        let newEducation = {
            ...education,
            [event.target.name]: value
        };
    
        if (event.target.name === 'currently_pursuing') {
            if (value) {
                // If currently_pursuing is set to true, set to_date to null
                newEducation.to_date = null;
            } else {
                // If currently_pursuing is set to false, set to_date back to the original value
                newEducation.to_date = original_to_date;
            }
        }
    
        setEducation(newEducation);
    };

    // Distribute button clicks to the appropriate handler
    const handleIconClick = (action) => {
        if (action === 'cancel') {
            setShowConfirmingCancel(true);
        }

        else if (action === 'delete') {
            setShowConfirmingDelete(true);
        }

        else if (action === 'add') {
            handleAddEducation();
        }
    };

    // Do not cancel adding a new education
    const handleCancelAbort = () => {
        setShowConfirmingCancel(false);
    };

    // Cancel adding a new education
    const handleCancelConfirm = () => {
        // Close the confirmation dialog
        setShowConfirmingCancel(false);

        // Notify the parent component that the add was cancelled
        onCancelAdd();
    };

    // Cancel deleting an education
    const handleCancelDelete = () => {
        setShowConfirmingDelete(false);
    };

    const handleDeleteEducation = async () => {
        try {
            const url = `https://api.foveaai.com/evaluate/web/candidates/${candidate_id}/educations/${education_id}`;
            const config = {
                headers: { Authorization: cookies.AuthorizationToken },
            };
            await axios.delete(url, config);
            if (onDelete) {
                onDelete(education_id);
            }
        } catch (error) {
            console.error("Error deleting education:", error);
        }
    };

    // Handle adding a new experience
    const handleUpdateEducation = () => {
        const url = `https://api.foveaai.com//evaluate/web/candidates/${candidate_id}/educations/${education_id}`;
        const data = {
            degree: education.degree,
            major: education.major,
            school: education.school,
            from_date: education.from_date ? new Date(education.from_date).toISOString() : null,
            to_date: education.to_date ? new Date(education.to_date).toISOString() : null,
            currently_pursuing: Boolean(education.currently_pursuing),
        };
        const config = {
            headers: { Authorization: cookies.AuthorizationToken },
        };
    
        return axios.put(url, data, config);  // Return the promise directly
    };

    const handleAddEducation = async () => {
        console.log("Adding education:", education);

        try {
            const url = `https://api.foveaai.com/evaluate/web/candidates/${candidate_id}/educations`
            const data = {
                degree: education.degree,
                major: education.major,
                school: education.school,
                from_date: education.from_date ? new Date(education.from_date).toISOString() : null,
                to_date: education.to_date ? new Date(education.to_date).toISOString() : null,
                currently_pursuing: Boolean(education.currently_pursuing),
            };
            const config = {
                headers: { Authorization: cookies.AuthorizationToken },
            };
            
            const post_response = await axios.post(url, data, config);

            if (post_response.status === 201 && onAdd) {
                onAdd(post_response.data);
            }

        } catch (error) {
            alert("Error adding education. Please ensure all fields are entered or cancel adding education.");
            console.error("Error adding education:", error);
        }
    };

    return (
        <Container className="education-detail-form">
            <div className="form-icons">
                <button
                    type="button"
                    className={`icon-button ${isNewEntry ? 'cancel-button' : 'delete-button'}`}
                    onClick={() => handleIconClick(isNewEntry ? 'cancel' : 'delete')}
                >
                    <span>×</span>
                </button>
                {isNewEntry && (
                    <button
                        type="button"
                        className="icon-button submit-button"
                        onClick={() => handleIconClick('add')}
                    >
                        <span>+</span>
                    </button>
                )}
            </div>
            {showConfirmingDelete && (
                <div className="delete-confirmation">
                    <p>Are you sure you want to delete this education?</p>
                    <Button type="button" color="secondary" onClick={handleCancelDelete}>Cancel</Button>
                    <Button type="button" color="danger" onClick={handleDeleteEducation}>Delete</Button>
                </div>
            )}
            {showConfirmingCancel && (
                <div className="delete-confirmation">
                    <p>Are you sure you want to discard your changes?</p>
                    <Button type="button" color="secondary" onClick={handleCancelAbort}>No, keep editing</Button>
                    <Button type="button" color="danger" onClick={handleCancelConfirm}>Yes, discard changes</Button>
                </div>
            )}
            <Form>
                <div className="education-form-section-heading">
                    <h3>{education.degree || 'New Education'}</h3>
                </div>
                <FormGroup>
                    <Label>Degree</Label>
                    <InputGroup>
                        <Input
                            type="text"
                            name="degree"
                            value={education.degree || ''}
                            onChange={handleChange}
                        />
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label>Major</Label>
                    <InputGroup>
                        <Input
                            type="text"
                            name="major"
                            value={education.major || ''}
                            onChange={handleChange}
                        />
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label>School</Label>
                    <InputGroup>
                        <Input
                            type="text"
                            name="school"
                            value={education.school || ''}
                            onChange={handleChange}
                        />
                    </InputGroup>
                </FormGroup>
                <div className="date-entry-container">
                    <FormGroup className="date-entry-field">
                        <Label>Start Date</Label>
                        <InputGroup>
                            <Input
                                type="date"
                                name="from_date"
                                value={education.from_date || ''}
                                onChange={handleChange}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="date-entry-field">
                        <Label>End Date</Label>
                        <InputGroup>
                            <Input
                                type="date"
                                name="to_date"
                                value={education.to_date || ''}
                                onChange={handleChange}
                                disabled={education.currently_pursuing}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="date-entry-field">
                        <Label>Currently Pursuing</Label>
                        <InputGroup>
                            <Input
                                type="checkbox"
                                name="currently_pursuing"
                                checked={education.currently_pursuing || false}
                                onChange={handleChange}
                            />
                        </InputGroup>
                    </FormGroup>
                </div>
            </Form>
        </Container>
    );
});

export default EducationDetailForm;