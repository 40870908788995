import "./PDFDownload.css";

import React, { useState, useEffect } from "react"; // Import useEffect
import axios from "axios";
import { Button, Container, Form, FormGroup, Label, Input } from "reactstrap";
import { Row, Col } from 'reactstrap';
import { useCookies } from "react-cookie";

const PDFDownload = ({
    analysisId = null,
    closeModal = null,
}) => {
    const [cookies, setCookie] = useCookies(["PDFSelections"]); // Use setCookie
    const defaultSelections = {
        job_fit_rating_normalized: true,
        primary_job_title: true,
        url: true,
        candidate_summary: true,
        city: true,
        state: true,
        zip_code: true,
        phone: true,
        email: true,
        notes: true,
        job_fit_narrative: true,
        interview_questions: true,
        skill_set: true,
        experience: true,
        education: true,
        user_first_name: true,
        user_last_name: true,
        user_phone: true,
        user_email: true,
        user_company: true
    };

    // Initialize selections from cookies or default to true
    const [selections, setSelections] = useState(cookies.PDFSelections || defaultSelections);

    // Update cookie whenever selections change
    useEffect(() => {
        const oneYearFromNow = new Date();
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1); // Set the cookie to expire in one year

        setCookie("PDFSelections", selections, { 
            path: '/',
            expires: oneYearFromNow // Set expiration date
        });
    }, [selections, setCookie]);

    const keyMapping = {
        "Candidate Rating": ["job_fit_rating_normalized"],
        "Primary Job Title": ["primary_job_title"],
        "Summary": ["candidate_summary"],
        "Strengths & Weaknesses": ["job_fit_narrative"],
        "Interview Questions": ["interview_questions"],
        "Skills": ["skill_set"],
        "Experience": ["experience"],
        "Education": ["education"],
        "Location": ["city", "state", "zip_code"],
        "Candidate Contact": ["phone", "email"],
        "Candidate Information Link": ["url"],
        "Recruiter Notes": ["notes"],
        "Recruiter Information": ["user_first_name", "user_last_name", "user_phone", "user_email", "user_company"]
    };

    function handleCheckboxChange(event, keys) {
        const isChecked = event.target.checked;
        setSelections(prevSelections => {
            const newSelections = { ...prevSelections };
            keys.forEach(key => {
                newSelections[key] = isChecked;
            });
            return newSelections;
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (analysisId) {
            try {
                const response = await axios.post(`https://api.foveaai.com/evaluate/web/analysis/${analysisId}/pdf`, selections, {
                    headers: {
                        Authorization: cookies.AuthorizationToken
                    },
                    responseType: 'blob' // This tells axios to treat the response as a binary blob
                });
                const downloadUrl = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = downloadUrl;
                const contentDisposition = response.headers['content-disposition'];
                let fileName = 'Candidate-Profile.pdf'; // Default file name
                if (contentDisposition) {
                    const parts = contentDisposition.split('=');
                    if (parts.length === 2) {
                        fileName = parts[1];
                        if (fileName.startsWith('"') && fileName.endsWith('"')) {
                            fileName = fileName.slice(1, -1); // Remove the double quotes
                        }
                    }
                }
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();

                // Debugging
                console.log('Response Headers:', response.headers)
                
                // Log the filename to the console
                console.log('Filename:', fileName);
            } catch (error) {
                console.error('Error downloading PDF:', error);
            } finally {
                if (closeModal) {
                    closeModal();
                }
            }
        }
    };

    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                <Row>
                    {Object.entries(keyMapping).map(([label, keys], index) => (
                        <Col md={6} key={label}>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        name={label}
                                        checked={keys.every(key => selections[key])}
                                        onChange={(event) => handleCheckboxChange(event, keys)}
                                    />{' '}
                                    {label}
                                </Label>
                            </FormGroup>
                        </Col>
                    ))}
                </Row>
                {closeModal && (
                    <Button
                        color="secondary"
                        onClick={closeModal}
                        style={{ marginTop: "20px", marginRight: "10px" }}
                    >
                        Cancel
                    </Button>
                )}
                <Button
                    color="primary"
                    type="submit"
                    style={{ marginTop: "20px", marginLeft: "10px"}}
                >
                    Download
                </Button>
            </Form>
        </Container>
    );
};

export default PDFDownload;