import "./LoginForm.css";

import { setCookie } from "../../utils/SecurityUtils";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";

import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "axios";

export default function LoginForm() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Get the token from the server
        setIsLoading(true);
        console.log("Logging in...");
        try {
            const response = await axios.post(
                "https://api.foveaai.com/evaluate/token",
                {
                    email: email,
                    password: password,
                }
            );

            // Handle the token
            const token = response.data.token;
            console.log(token);

            // Store the token in a cookie
            setCookie("AuthorizationToken", token, 30);

            // Navigate to the dashboard if the token is valid
            window.location.href = "/dashboard";
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setError("Invalid email address or password");
            } else {
                setError(`Network error experienced while logging in. Please try again later and alert us if the error persists.`);
                console.error("Error fetching the token", error);
            }
        } finally {
            setIsLoading(false);
        }

        console.log(email, password);
    };

    return (
        <div className="login-form-container">
            {isLoading && <LoadingOverlay />}
            <Form className="login-form" onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="mt-2">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>

                <div className="d-flex justify-content-center">
                    <Button variant="primary" type="submit" className="mt-4">
                        Login
                    </Button>
                </div>

                {/* Display an error message if the login fails */}
                {error && (
                    <div className="alert alert-danger mt-4 d-flex justify-content-center">
                        {error}
                    </div>
                )}
            </Form>
        </div>
    );
}
