import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const CandidateRatingChart = ({ minYears, maxYears, valueToGraph }) => {
    const [isVisible, setIsVisible] = useState(false);

    // Ensure minYears and maxYears are in correct order and not equal
    let x1 = minYears;
    let x2 = maxYears;
    if (x1 > x2) {
        [x1, x2] = [x2, x1];
    } else if (x1 === x2) {
        x2 = x1 + 1;
    }

    // Set Gaussian curve parameters
    const a = 100;
    const h = (x1 + x2) / 2;
    const b = Math.log(2) / ((x1 - x2) / 2) ** 2;

    // Set fade in transition parameters
    const fadeInDuration = 1.5; // seconds
    const fadeInDelay = 100; // milliseconds

    // Generate points for the curve
    const generatePoints = () => {
        const points = [];
        const range = x2 - x1;
        const start = x1 - range;
        const end = x2 + range;
        const step = range / 25;

        for (let x = start; x <= end; x += step) {
            const y = a * Math.exp(-b * (x - h) ** 2);
            if (y >= 0) {
                points.push({ x, y });
            }
        }
        return points;
    };

    const curvePoints = generatePoints();

    // Calculate the y value for valueToGraph
    const valueY = a * Math.exp(-b * (valueToGraph - h) ** 2);

    // Find the left-most point where y is approximately 0
    const leftZeroPoint = curvePoints.find((point) => point.y < 0.1);

    const data = {
        datasets: [
            {
                label: "Gaussian Curve",
                data: curvePoints,
                borderColor: "rgba(9, 0, 136, 1)",
                borderWidth: 2,
                fill: false,
                pointRadius: 0,
            },
            {
                label: "Filled Area",
                data: curvePoints.filter((point) => point.x <= valueToGraph),
                borderColor: "rgba(9, 0, 136, 0.2)",
                backgroundColor: "rgba(9, 0, 136, 0.2)",
                fill: "start",
                pointRadius: 0,
            },
            {
                label: "Value to Graph",
                data: [{ x: valueToGraph, y: valueY }],
                borderColor: "rgba(255, 50, 132, 1)",
                backgroundColor: "rgba(255, 50, 132, 1)",
                pointRadius: 4,
                pointHoverRadius: 7,
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            x: {
                type: "linear",
                position: "bottom",
                ticks: {
                  display: true,
                  callback: function(value, index, ticks) {
                    if (index === 0) return 'Under';
                    if (index === ticks.length - 1) return 'Over';
                    return '';
                  },
                  font: {
                    size: 14,
                  },
                  color: 'rgba(0, 0, 0, 0.6)',
                },
                min: leftZeroPoint ? leftZeroPoint.x : undefined,
                max: x2 + (x2 - x1), // Extend the right side a bit
                grid: {
                  display: false,
                },
            },
            y: {
                min: 0,
                max: 100,
                ticks: {
                    stepSize: 20,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            filler: {
                propagate: false,
            },
            tooltip: {
                enabled: false
            }
        },
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, fadeInDelay);

        return () => clearTimeout(timer);
    }, []);

    const chartStyle = {
        opacity: isVisible ? 1 : 0,
        transition: `opacity ${fadeInDuration}s ease-in-out`
    };

    return (
        <div style={chartStyle}>
            <Line data={data} options={options} />
        </div>
    );
};

export default CandidateRatingChart;
