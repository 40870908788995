import React, { useState } from "react";
import { InfoCircle } from "react-bootstrap-icons";
import { Modal } from "react-bootstrap";

import "./InfoIcon.css";

const InfoIcon = ({ title, content }) => {
    const [showModal, setShowModal] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowModal(true);
    };

    return (
        <>
            <InfoCircle className="info-icon" onClick={handleClick} />
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                centered // This prop centers the modal vertically
                className="info-modal" // Add a custom class for styling
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{content}</Modal.Body>
            </Modal>
        </>
    );
};

export default InfoIcon;
