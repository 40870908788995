import "./ExperienceDetailForm.css";

import axios from "axios";
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Form, FormGroup, Label, InputGroup, Input, Container, Button } from "reactstrap";
import { useCookies } from "react-cookie";

const ExperienceDetailForm = forwardRef(({
    experience_object = null,
    experience_id = null,
    candidate_id = null,
    isNewEntry = false,
    onDelete = null,
    onCancelAdd = null,
    onAdd = null,
}, ref) => {

    const [original_to_date, setOriginalToDate] = useState(null);
    const [cookies] = useCookies(["user"]);
    const [experience, setExperience] = useState({
        id: null,
        candidate_id: null,
        company: null,
        title: null,
        summary: null,
        from_date: null,
        to_date: null,
        current_job: null,
    });
    const [showConfirmingDelete, setShowConfirmingDelete] = useState(false);
    const [showConfirmingCancel, setShowConfirmingCancel] = useState(false);

    // Set the experience object and fill fields if it is passed in
    useEffect(() => {
        if (experience_object) {
            setExperience(experience_object);
            setOriginalToDate(experience_object.to_date);
        }
    }, [experience_object]);

    // Expose the update, add, and new entry status to the parent component
    useImperativeHandle(ref, () => ({
        handleUpdateExperience,
        handleAddExperience,
        isNewEntry,
    }));

    // Handle changes to the form fields
    const handleChange = (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        let newExperience = {
            ...experience,
            [event.target.name]: value
        };
    
        if (event.target.name === 'current_job') {
            if (value) {
                // If current_job is set to true, set to_date to null
                newExperience.to_date = null;
            } else {
                // If current_job is set to false, set to_date back to the original value
                newExperience.to_date = original_to_date;
            }
        }
    
        setExperience(newExperience);
    };

    // Distribute button clicks to the appropriate handler
    const handleIconClick = (action) => {
        if (action === 'cancel') {
            setShowConfirmingCancel(true);
        }
        
        else if (action === 'delete') {
            setShowConfirmingDelete(true);
        }

        else if (action === 'add') {
            handleAddExperience();
        }
    };

    // Do not cancel adding a new experience
    const handleCancelAbort = () => {
        setShowConfirmingCancel(false);
    };

    // Cancel adding a new experience
    const handleCancelConfirm = () => {
        // Close the confirmation dialog
        setShowConfirmingCancel(false);

        // Notify the parent component that the add was cancelled
        onCancelAdd();
    };

    // Cancel deleting an experience
    const handleCancelDelete = () => {
        setShowConfirmingDelete(false);
    };

    // Handle experience deletes
    const handleDeleteExperience = async () => {
        try {
            const url = `https://api.foveaai.com/evaluate/web/candidates/${candidate_id}/experiences/${experience_id}`;
            const config = {
                headers: { Authorization: cookies.AuthorizationToken },
            };
            await axios.delete(url, config);
            if (onDelete) {
                onDelete(experience_id);
            }
        } catch (error) {
            console.error("Error deleting experience:", error);
        }
    };

    // Handle adding a new experience
    const handleUpdateExperience = () => {
        const url = `https://api.foveaai.com//evaluate/web/candidates/${candidate_id}/experiences/${experience_id}`;
        const data = {
            title: experience.title,
            company: experience.company,
            summary: experience.summary,
            from_date: new Date(experience.from_date).toISOString(),
            to_date: new Date(experience.to_date).toISOString(),
            current_job: Boolean(experience.current_job),
        };
        const config = {
            headers: { Authorization: cookies.AuthorizationToken },
        };
    
        return axios.put(url, data, config);  // Return the promise directly
    };

    const handleAddExperience = async () => {
        console.log("Adding experience:", experience);

        try {
            const url = `https://api.foveaai.com/evaluate/web/candidates/${candidate_id}/experiences`
            const data = {
                title: experience.title,
                company: experience.company,
                summary: experience.summary,
                from_date: new Date(experience.from_date).toISOString(),
                to_date: new Date(experience.to_date).toISOString(),
                current_job: Boolean(experience.current_job),
            };
            const config = {
                headers: { Authorization: cookies.AuthorizationToken },
            };
            
            const post_response = await axios.post(url, data, config);

            if (post_response.status === 201 && onAdd) {
                onAdd(post_response.data);
            }

        } catch (error) {
            alert("Error adding experience. Please ensure all fields are entered or cancel adding experience.");
            console.error("Error adding experience:", error);
        }
    };

    return (
        <Container className="experience-detail-form">
            <div className="form-icons">
                <button
                    type="button"
                    className={`icon-button ${isNewEntry ? 'cancel-button' : 'delete-button'}`}
                    onClick={() => handleIconClick(isNewEntry ? 'cancel' : 'delete')}
                >
                    <span>×</span>
                </button>
                {isNewEntry && (
                    <button
                        type="button"
                        className="icon-button submit-button"
                        onClick={() => handleIconClick('add')}
                    >
                        <span>+</span>
                    </button>
                )}
            </div>
            {showConfirmingDelete && (
                <div className="delete-confirmation">
                    <p>Are you sure you want to delete this experience?</p>
                    <Button type="button" color="secondary" onClick={handleCancelDelete}>Cancel</Button>
                    <Button type="button" color="danger" onClick={handleDeleteExperience}>Delete</Button>
                </div>
            )}
            {showConfirmingCancel && (
                <div className="delete-confirmation">
                    <p>Are you sure you want to discard your changes?</p>
                    <Button type="button" color="secondary" onClick={handleCancelAbort}>No, keep editing</Button>
                    <Button type="button" color="danger" onClick={handleCancelConfirm}>Yes, discard changes</Button>
                </div>
            )}
            <Form>
                <div className="experience-form-section-heading">
                    <h3>{experience.title || 'New Experience'}</h3>
                </div>
                <FormGroup>
                    <Label>Title</Label>
                    <InputGroup>
                        <Input
                            type="text"
                            name="title"
                            value={experience.title || ''}
                            onChange={handleChange}
                        />
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label>Company</Label>
                    <InputGroup>
                        <Input
                            type="text"
                            name="company"
                            value={experience.company || ''}
                            onChange={handleChange}
                        />
                    </InputGroup>
                </FormGroup>
                <div className="date-entry-container">
                    <FormGroup className="date-entry-field">
                        <Label>Start Date</Label>
                        <InputGroup>
                            <Input
                                type="date"
                                name="from_date"
                                value={experience.from_date || ''}
                                onChange={handleChange}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="date-entry-field">
                        <Label>End Date</Label>
                        <InputGroup>
                            <Input
                                type="date"
                                name="to_date"
                                value={experience.to_date || ''}
                                onChange={handleChange}
                                disabled={experience.current_job}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="date-entry-field">
                        <Label>Current Job</Label>
                        <InputGroup>
                            <Input
                                type="checkbox"
                                name="current_job"
                                checked={experience.current_job || false}
                                onChange={handleChange}
                            />
                        </InputGroup>
                    </FormGroup>
                </div>
                <FormGroup>
                    <Label>Summary</Label>
                    <InputGroup>
                        <Input
                            type="textarea"
                            name="summary"
                            value={experience.summary || ''}
                            onChange={handleChange}
                        />
                    </InputGroup>
                </FormGroup>
            </Form>
        </Container>
    );
});

export default ExperienceDetailForm;