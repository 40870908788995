import React from "react";

// Utility function to convert newlines and tabs into HTML equivalents
export const TextWithNewLines = ({ text = "" }) => {
    const formattedText = (text || "").split("\n").map((line, index) => (
        <div key={index}>
            {line.split("\t").map((segment, index) => (
                <React.Fragment key={index}>
                    <span style={{ marginRight: "2em" }}>{segment}</span>
                </React.Fragment>
            ))}
        </div>
    ));

    return <div>{formattedText}</div>;
};


export const Lerp = (start, end, t) => {
    return Math.round(start + (end - start) * t);
};


export const GetColorForJobFitRating = (analysis) => {
    const {
        job_fit_rating_calculation_min_years: min,
        job_fit_rating_calculation_max_years: max,
        job_fit_rating_calculation_ideal_years: ideal,
        job_fit_rating_calculation_total: total,
    } = analysis;

    const range = max + min;

    if (total < min) {
        const t = total / min;
        return `rgb(${Lerp(26, 0, t)}, ${Lerp(35, 131, t)}, ${Lerp(126, 143, t)})`;
    } else if (total < ideal) {
        const t = (total - min) / (ideal - min);
        return `rgb(${Lerp(0, 27, t)}, ${Lerp(131, 94, t)}, ${Lerp(143, 32, t)})`;
    } else if (total < max) {
        const t = (total - ideal) / (max - ideal);
        return `rgb(${Lerp(27, 100, t)}, ${Lerp(94, 221, t)}, ${Lerp(32, 23, t)})`;
    } else if (total < range) {
        const t = (total - max) / (range - max);
        return `rgb(${Lerp(100, 239, t)}, ${Lerp(221, 108, t)}, ${Lerp(23, 0, t)})`;
    }
    return "#F7630C";
};


export const GetColorForTotalExperience = (rating) => {
    const minColor = { r: 26, g: 35, b: 126 };  // Dark blue
    const maxColor = { r: 0x4b, g: 0xc3, b: 0x14 };    // Green

    const t = Math.min(Math.max(rating, 0), 100) / 100;  // Ensure rating is between 0 and 100, then normalize to 0-1

    return `rgb(${
        Lerp(minColor.r, maxColor.r, t)
    }, ${
        Lerp(minColor.g, maxColor.g, t)
    }, ${
        Lerp(minColor.b, maxColor.b, t)
    })`;
};


// New function to choose the appropriate color based on rating type
export const GetColorForRating = (analysis, ratingType) => {
    if (ratingType === 'jobFit') {
        return GetColorForJobFitRating(analysis);
    } else {
        return GetColorForTotalExperience(analysis.total_experience_rating_normalized);
    }
};