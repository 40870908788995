import "./SettingsPage.css";

import UsageTable from "../../components/UsageTable/UsageTable";

import React from "react";
import { Tabs, Tab } from "react-bootstrap";

function SettingsPage() {
    return (
        <div className="settingsContainer">
            <div className="settingsHeader">
                <h1>Settings</h1>
            </div>
            <div className="settingsTabs">
                <Tabs
                    defaultActiveKey="user"
                    id="settings-tabs"
                    className="settingsTabs"
                >
                    {/* Tabs disabled but held for future use */}
                    {/*
                    <Tab eventKey="user" title="User Settings" className="settingsTab">
                        <p>User settings content...</p>
                    </Tab>
                    <Tab eventKey="billing" title="Billing" className="settingsTab">
                        <p>Billing content...</p>
                    </Tab>
                    */}
                    <Tab eventKey="usage" title="Usage" className="settingsTab">
                        <div className="usageTableContainer">
                            <UsageTable />
                        </div>
                    </Tab>
                    {/* Add more tabs as needed */}
                </Tabs>
            </div>
        </div>
    );
}

export default SettingsPage;
