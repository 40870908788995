import "./App.css";
import Header from "./components/Header/HeaderMenu";
import Footer from "./components/Footer/Footer";
import LoginPage from "./pages/LoginPage/LoginPage";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import JobPage from "./pages/JobPage/JobPage";
import AnalysisPage from "./pages/AnalysisPage/AnalysisPage";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import EulaPage from "./pages/EulaPage/EulaPage";
import ScrollToTop from "./utils/ScrollToTop";
import { ProtectedRoute } from "./utils/SecurityUtils";

import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";

function App() {
    return (
        <Router>
            <ScrollToTop />
            <div className="App content-container">
                <Header />
                <Routes>
                    <Route 
                        path="/login"
                        element={
                            <LoginPage />
                        }
                    />
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute requireEula={true}>
                                <DashboardPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/job/:jobId"
                        element={
                            <ProtectedRoute requireEula={true}>
                                <JobPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/analysis/:analysisId"
                        element={
                            <ProtectedRoute requireEula={true}>
                                <AnalysisPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/settings"
                        element={
                            <ProtectedRoute requireEula={true}>
                                <SettingsPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/eula"
                        element={
                            <ProtectedRoute requireEula={false}>
                                <EulaPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="*"
                        element={<Navigate to="/login" replace />}
                    />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
