import "./JobTable.css";

import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const JobTable = () => {
    const [jobs, setJobs] = useState([]);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: "ascending",
    });
    const [cookies] = useCookies(["AuthorizationToken"]);
    const [isLoading, setIsLoading] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        const fetchJobs = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(
                    "https://api.foveaai.com/evaluate/web/jobs",
                    {
                        headers: { Authorization: cookies.AuthorizationToken },
                    }
                );
                const jobsData =
                    typeof response.data === "string"
                        ? JSON.parse(response.data)
                        : response.data;
                setJobs(jobsData);
            } catch (error) {
                console.error("Error fetching jobs:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchJobs();
    }, [cookies.AuthorizationToken]);

    const navigateToJobDetail = (jobId) => {
        // Navigate to '/job/:jobId' route
        navigate(`/job/${jobId}`);
    };

    const requestSort = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        }
        setSortConfig({ key, direction });
    };

    const sortIndicator = (columnName) => {
        if (sortConfig.key === columnName) {
            return sortConfig.direction === "ascending" ? "▲" : "▼";
        }
        return "";
    };

    const sortedJobs = React.useMemo(() => {
        let sortableItems = [...jobs];
        if (sortConfig.key !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === "ascending" ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === "ascending" ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [jobs, sortConfig]);

    return (
        <div className="job-table">
            {isLoading && <LoadingOverlay />}
            <Table bordered hover>
                <thead>
                    <tr>
                        <th onClick={() => requestSort("title")}>
                            Title {sortIndicator("title")}
                        </th>
                        <th onClick={() => requestSort("client")}>
                            Client {sortIndicator("client")}
                        </th>
                        <th onClick={() => requestSort("contact")}>
                            Contact {sortIndicator("contact")}
                        </th>
                        <th onClick={() => requestSort("locality")}>
                            City {sortIndicator("locality")}
                        </th>
                        <th onClick={() => requestSort("administrative_area")}>
                            State {sortIndicator("administrative_area")}
                        </th>
                    </tr>
                </thead>
                <tbody className="table-data">
                    {Array.isArray(jobs) && jobs.length > 0 ? (
                        sortedJobs.map((job) => (
                            <tr key={job.job_id}>
                                <td
                                    onClick={() =>
                                        navigateToJobDetail(job.job_id)
                                    }
                                >
                                    {job.title}
                                </td>
                                <td
                                    onClick={() =>
                                        navigateToJobDetail(job.job_id)
                                    }
                                >
                                    {job.client}
                                </td>
                                <td
                                    onClick={() =>
                                        navigateToJobDetail(job.job_id)
                                    }
                                >
                                    {job.contact}
                                </td>
                                <td
                                    onClick={() =>
                                        navigateToJobDetail(job.job_id)
                                    }
                                >
                                    {job.locality}
                                </td>
                                <td
                                    onClick={() =>
                                        navigateToJobDetail(job.job_id)
                                    }
                                >
                                    {job.administrative_area}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">
                                Add your first job description using the Add Job
                                button.
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default JobTable;
